import React from 'react'
import { array, string } from 'prop-types'
import { Carousel } from 'antd'

import './index.less'

const CarouselTemplate = ({ carousels, className, ...rest }) =>
  carousels.length > 0 && (
    <Carousel
      draggable
      lazyLoad
      arrows
      autoplay
      autoplaySpeed={5000}
      className={`carousel ${className}`}
      {...rest}
    >
      {carousels.map((node, i) => {
        const { image, imageMobile } = node

        const content = (
          <>
            <div
              className="carousel__slide-bg"
              css={{
                backgroundImage: `url(${imageMobile || image})`,
                '@media (min-width: 768px)': {
                  backgroundImage: `url(${image})`
                }
              }}
            />
            <div
              className="carousel__custom-content"
              dangerouslySetInnerHTML={{
                __html: node.html
              }}
            />
          </>
        )

        if (node.link) {
          return (
            <a
              key={node.key}
              href={node.link}
              className={`carousel__slide carousel__custom carousel__${i}`}
              {...(node.link.indexOf('http') > -1
                ? {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  }
                : {})}
            >
              {content}
            </a>
          )
        }

        return (
          <div
            key={node.key}
            className={`carousel__slide carousel__custom carousel__${node.i}`}
          >
            {content}
          </div>
        )
      })}
    </Carousel>
  )

CarouselTemplate.propTypes = {
  carousels: array,
  className: string
}

CarouselTemplate.defaultProps = {
  carousels: [],
  className: ''
}

export default CarouselTemplate
