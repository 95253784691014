import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'

import styles from './index.module.less'

const ShowMore = () => {
  const [shown, setShown] = useState(false)
  const btnRef = useRef(null)
  const handleMoreClick = () => {
    setShown(!shown)
  }

  useEffect(() => {
    const moreDoms = btnRef.current.buttonNode.previousSibling.querySelectorAll(
      '.more'
    )

    for (let i = 0; i < moreDoms.length; i++) {
      moreDoms[i].style.display = shown ? 'block' : 'none'
    }
  }, [shown])

  return (
    <Button
      ref={btnRef}
      className={`${styles.showMore} ${shown ? styles.shown : ''}`}
      icon="double-right"
      onClick={handleMoreClick}
    >
      {shown ? (
        <FormattedMessage id="partner.less" />
      ) : (
        <FormattedMessage id="partner.more" />
      )}
    </Button>
  )
}

export default ShowMore
