import React, { useState, useEffect, useRef } from 'react'
import { string, node, bool } from 'prop-types'
import MediaQuery from 'react-responsive'

import CardSlide from 'components/CardSlide'
import styles from './index.module.less'

const PageSection = ({ isSlideInHtml, html, className, children, ...rest }) => {
  const domRef = useRef(null)
  const [cardSlides, setCardSlides] = useState([])
  useEffect(() => {
    if (!isSlideInHtml) return

    // init cardSlides
    const slideDoms = domRef.current.querySelectorAll('li')
    const slides = []

    if (slideDoms) {
      for (let i = 0; i < slideDoms.length; i += 1) {
        slides.push(slideDoms[i])
        slideDoms[i].remove()
      }
    }

    setCardSlides(slides)
  }, [html])

  return (
    <section className={`page-section ${className}`} {...rest}>
      <div className="container">
        <div
          ref={domRef}
          className="page-section__html"
          dangerouslySetInnerHTML={{
            __html: html
          }}
        />
        {children}
        <MediaQuery minDeviceWidth={768}>
          {matches =>
            cardSlides.length > 0 && (
              <div className={`card-slides ${styles.cardList}`}>
                <CardSlide
                  arrows
                  slidesToScroll={matches ? 3 : 1}
                  slidesToShow={matches ? 3 : 1}
                  centerMode={!matches}
                >
                  {cardSlides.map(
                    (slide, index) =>
                      slide && (
                        <div key={index} className={styles.card}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: slide.outerHTML
                            }}
                          />
                        </div>
                      )
                  )}
                </CardSlide>
              </div>
            )
          }
        </MediaQuery>
      </div>
    </section>
  )
}

PageSection.propTypes = {
  isSlideInHtml: bool,
  html: string,
  className: string,
  children: node
}

PageSection.defaultTypes = {
  isSlideInHtml: false,
  html: '',
  className: '',
  children: null
}

export default PageSection
