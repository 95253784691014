import React from 'react'
import { node } from 'prop-types'
import { Carousel } from 'antd'

import styles from './index.module.less'

const CardSlide = ({ children, ...rest }) => {
  return (
    <Carousel
      draggable
      className={styles.carousel}
      centerMode
      slidesToShow={1}
      {...rest}
    >
      {children}
    </Carousel>
  )
}

CardSlide.propTypes = {
  children: node.isRequired
}

export default CardSlide
