import React from 'react'
import { object } from 'prop-types'
import { Link } from 'gatsby'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import Img from 'gatsby-image'

import styles from './index.module.less'

const NewsCard = ({ intl, data }) => {
  const { fields, frontmatter, excerpt } = data

  return (
    <div className={`shadow-card ${styles.newsCard}`}>
      <Link to={fields.slug}>
        {frontmatter.featuredImage && (
          <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} />
        )}
        <div className={styles.newsTitle}>
          <p>
            {moment(new Date(frontmatter.date)).format(
              intl.formatMessage({ id: 'date.format' })
            )}
          </p>
          <h3>{frontmatter.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: excerpt }} />
        </div>
      </Link>
    </div>
  )
}

NewsCard.propTypes = {
  intl: object.isRequired,
  data: object.isRequired
}

export default injectIntl(NewsCard)
