import React from 'react'
import { object } from 'prop-types'
import { Tabs } from 'antd'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SEO from 'components/seo'
import Carousel from 'components/Carousel'
import PageSection from 'components/PageSection'
import ShowMore from 'components/ShowMore'
import NewsCenter from 'components/NewsCenter'

import { getHomeSlug } from 'utils'
import styles from './index.module.less'

const Home = ({ data, pageContext }) => {
  const [introTitle, ...intro] = data.intro.edges
  const advantage = data.advantage.edges
  const carousels = data.carousel.edges.map(({ node }) => {
    const {
      id: key,
      html,
      frontmatter: { featuredImage, featuredImageMobile, link }
    } = node

    return {
      key,
      html,
      image: featuredImage && featuredImage.childImageSharp.fluid.src,
      imageMobile:
        featuredImageMobile && featuredImageMobile.childImageSharp.fluid.src,
      link
    }
  })
  const homeSlug = getHomeSlug(pageContext)

  return (
    <>
      <SEO />
      <Carousel className="home-carousel" carousels={carousels} />
      <PageSection className={styles.info} html={data.info && data.info.html} />
      <PageSection className={styles.advantage}>
        <Tabs
          defaultActiveKey={advantage[1] && advantage[1].node.id}
          size="large"
          animated={false}
        >
          {advantage.map(({ node }, i) => {
            const { id, frontmatter, html } = node
            const itemClass = styles[`advantageContent_${i + 1}`]

            return (
              <Tabs.TabPane tab={frontmatter.title} key={id}>
                <div
                  className={`${styles.advantageContent} ${itemClass}`}
                  style={{
                    backgroundImage: `url(${frontmatter.featuredImage &&
                      frontmatter.featuredImage.childImageSharp.fluid.src})`
                  }}
                >
                  <div
                    className="container"
                    dangerouslySetInnerHTML={{
                      __html: html
                    }}
                  />
                </div>
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </PageSection>
      <PageSection>
        <h2>{introTitle.node.frontmatter.title}</h2>
        <h4>{introTitle.node.frontmatter.description}</h4>
        <ul className={styles.intro}>
          {intro.map(({ node }) => {
            const { id, frontmatter, html } = node

            return (
              <li className="shadow-card" key={id}>
                <div
                  className={styles.introBg}
                  style={{
                    backgroundImage: `url(${frontmatter.featuredImage &&
                      frontmatter.featuredImage.childImageSharp.fluid.src})`
                  }}
                >
                  <h2>
                    {frontmatter.iconImage && (
                      <Img
                        fluid={frontmatter.iconImage.childImageSharp.fluid}
                      />
                    )}
                    {frontmatter.title}
                  </h2>
                  <h4>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: frontmatter.description
                      }}
                    />
                  </h4>
                </div>
                <div
                  className={styles.introContentList}
                  dangerouslySetInnerHTML={{
                    __html: html
                  }}
                />
              </li>
            )
          })}
        </ul>
      </PageSection>
      <PageSection
        isSlideInHtml
        className={`${styles.caseSection} page-section--gray`}
        html={data.case && data.case.html}
      />
      <NewsCenter homeSlug={homeSlug} list={data.postList.edges} />
      <PageSection
        className={`${styles.partnerList} page-section--gray`}
        html={data.partner && data.partner.html}
      >
        <ShowMore />
      </PageSection>
      <PageSection
        className={`${styles.about} page-section--full`}
        html={data.about && data.about.html}
      />
      <PageSection
        className="contact-section page-section--banner"
        style={{
          backgroundImage: `url(${data.contact.frontmatter.featuredImage &&
            data.contact.frontmatter.featuredImage.childImageSharp.fluid.src})`
        }}
        html={data.contact && data.contact.html}
      />
    </>
  )
}

Home.propTypes = {
  data: object.isRequired,
  pageContext: object.isRequired
}

export default Home

export const pageQuery = graphql`
  query Home($locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    carousel: allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "//home/carousel//" }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            link
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 2880) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featuredImageMobile {
              childImageSharp {
                fluid(maxWidth: 750) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
    info: markdownRemark(
      fields: { locale: { eq: $locale } }
      fileAbsolutePath: { regex: "//home/info//" }
    ) {
      id
      html
    }
    advantage: allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "//home/advantage//" }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
    intro: allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "//home/intro//" }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            iconImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
    case: markdownRemark(
      fields: { locale: { eq: $locale } }
      fileAbsolutePath: { regex: "//home/case//" }
    ) {
      id
      html
    }
    partner: markdownRemark(
      fields: { locale: { eq: $locale } }
      fileAbsolutePath: { regex: "//home/partner//" }
    ) {
      id
      html
    }
    about: markdownRemark(
      fields: { locale: { eq: $locale } }
      fileAbsolutePath: { regex: "//home/about//" }
    ) {
      id
      html
    }
    contact: markdownRemark(
      fields: { locale: { eq: $locale } }
      fileAbsolutePath: { regex: "//contact//" }
    ) {
      id
      html
      frontmatter {
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    postList: allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "//news//" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`
