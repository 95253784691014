import React from 'react'
import { string, object, array } from 'prop-types'
import { Link } from 'gatsby'
import MediaQuery from 'react-responsive'
import moment from 'moment'
import { injectIntl, FormattedMessage } from 'react-intl'

import PageSection from 'components/PageSection'
import CardSlide from 'components/CardSlide'
import NewsCard from './NewsCard'

import styles from './index.module.less'

const NewsCenter = ({ intl, list, homeSlug }) => {
  const [firstPost, ...restPosts] = list

  return (
    <PageSection className={`${styles.newsSection} page-section`}>
      <h2>
        <FormattedMessage id="breadcrumb.news" />
      </h2>
      <p>
        <Link className="app-section__more" to={`${homeSlug}news`}>
          <FormattedMessage id="postList.readMore" />
        </Link>
      </p>
      <div className={styles.newsList}>
        <MediaQuery minDeviceWidth={768}>
          {matches => {
            return matches ? (
              <>
                {firstPost && <NewsCard data={firstPost.node} />}
                <ul>
                  {restPosts.map(({ node }) => {
                    return (
                      <Link
                        to={node.fields.slug}
                        key={node.id}
                        className={styles.newsTitle}
                      >
                        <p>
                          {moment(new Date(node.frontmatter.date)).format(
                            intl.formatMessage({ id: 'date.format' })
                          )}
                        </p>
                        <h3 title={node.frontmatter.title}>
                          {node.frontmatter.title}
                        </h3>
                      </Link>
                    )
                  })}
                </ul>
              </>
            ) : (
              <CardSlide arrows centerMode slidesToShow={1}>
                {list.map(({ node }) => (
                  <NewsCard key={node.id} data={node} />
                ))}
              </CardSlide>
            )
          }}
        </MediaQuery>
      </div>
    </PageSection>
  )
}

NewsCenter.propTypes = {
  intl: object.isRequired,
  homeSlug: string.isRequired,
  list: array
}

NewsCenter.defaultProps = {
  list: []
}

export default injectIntl(NewsCenter)
